import { CONTENTTYPE, META_TYPE } from "@utils/constants";
import React from "react";
import { Helmet } from "react-helmet";
import { getEnvironmentConstants } from "@utils/common";

function MainSeo({ metaTitle, metaDescription, pageURL, metaUrL, metaType, canonical }) {
  let ogUrl = `${getEnvironmentConstants().ENV_URL}${metaUrL}`,
    ogImage = "https://tstatic.videoready.tv/cms-ui/images/custom-content/1714977843226.svg",
    isMetaType = [META_TYPE.PI_PAGE, META_TYPE.HELP_CENTER, META_TYPE.SUBSCRIPTION, META_TYPE.APP_DOWNLOAD].includes(metaType);

  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:image:width" content="948" />
      <meta property="og:image:height" content="534" />
      {isMetaType && <meta property="og:type" content={"website"} />}
      {isMetaType && <meta name="twitter:title" content={metaTitle} />}
      {isMetaType && <meta name="twitter:description" content={metaDescription} />}
      {canonical ? (pageURL && <link rel="canonical" href={pageURL} />)
        : <link rel="canonical" href={`${window.location.origin}${window.location.pathname?.toLowerCase()}`} />}
    </Helmet>
  )
}

export default React.memo(MainSeo);