export const ACTION = {
    FETCH_HOME_DATA: 'FETCH_HOME_DATA',
    FETCH_HOME_DATA_HEIRARCHY: 'FETCH_HOME_DATA_HEIRARCHY',
    FETCH_RAIL_CONTENT: 'FETCH_RAIL_CONTENT',
    FETCH_HOME_SCREEN__DATA: 'FETCH_HOME_SCREEN__DATA',
    RESET_HOME_DATA: 'RESET_HOME_DATA',
    CONTINUE_WATCHING: 'CONTINUE_WATCHING',
    TVOD_DATA: 'TVOD_DATA',
    DUNNING_RECHARGE: 'DUNNING_RECHARGE',
    TA_HERO_BANNER: 'TA_HERO_BANNER',
    TA_RAIL_DATA: 'TA_RAIL_DATA',
    FETCH_PUBNUB_HISTORY: 'FETCH_PUBNUB_HISTORY',
    SAVE_PARENTAL_LOCK: 'SAVE_PARENTAL_LOCK',
    RESET_RAIL_CONTENT: 'RESET_RAIL_CONTENT',
    SEO_DATA: 'SEO_DATA',
    SET_APP_LAUNCH_COUNTER: 'SET_APP_LAUNCH_COUNTER',
    GET_APP_LISTING_FOR_RECOMMENDATION: "GET_APP_LISTING_FOR_RECOMMENDATION"
};

export const TVOD_MAX = 10;

export const DRP_STATE = {
    TA: 'TA',
    TA_GUEST: 'TA_GUEST',
    VR: 'VR',
}
