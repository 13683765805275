import store from "@src/store";
import { isUserloggedIn } from "@utils/common";
import { FEATURES, VARIANT_DETAILS, VARIENT_VALUE } from "@utils/constants/feature";
import mixPanelConfig from "@utils/mixpanel";
import MIXPANEL from "@constants/mixpanel";
import ENV_CONFIG from "@config/environment/index";
import { GEMINI_SOURCE, PAGE_TYPE } from "@utils/constants";
import get from "lodash/get";

/**
 * Finds the default variant of a given feature based on its key.
 *
 * @param {Array} data - The list of features.
 * @param {string} featureKey - The key of the feature to find.
 * @returns {Object|null} The default variant object, or null if not found.
 */
export const getVariantTestKey = (data, featureKey) => {
  const feature = data?.find((item) => item?.featureKey === featureKey); // Find the feature by its key

  if (!feature || !feature?.assignedTestVariant) {
    return null; // Return null if the feature or its assignedTestVariant is not found
  }

  // Return the testKey of the assignedTestVariant
  return feature?.assignedTestVariant?.testKey || null;
};

/**
 * Finds the description of a given feature based on its key.
 *
 * @param {Array} data - The list of features.
 * @param {string} featureKey - The key of the feature to find.
 * @returns {string|null} The description, or null if not found.
 */
export const getVariantDescription = (data, featureKey) => {
  const feature = data?.find((item) => item?.featureKey === featureKey); // Find the feature by its key

  if (!feature || !feature?.assignedTestVariant) {
    return null; // Return null if the feature or its assignedTestVariant is not found
  }

  // Return the description of the assignedTestVariant
  return feature?.assignedTestVariant?.description || null;
};

export const getExperimentKey = (data, featureKey) => {
  // Find the feature by its key
  const feature = data?.find((item) => item?.featureKey === featureKey);

  if (!feature || !feature?.experimentKey) {
    return null; // Return null if the feature or its experimentKey is not found
  }

  // Return the experimentKey of the feature
  return feature?.experimentKey || null;
};

export const getSegmentKey = (responseData, featureKey) => {
 
  const feature = responseData?.find((item) => item?.featureKey === featureKey );

  if (!feature || !feature?.segment) {
    return null; // Return null if the feature or its experimentKey is not found
  }

  return feature?.segment || null; // Return segment if found, otherwise null
};

/**
 * Gets all feature keys and their default variants for guest users.
 *
 * @param {Array} data - The list of features.
 * @returns {Array} List of feature keys with their default variants.
 */
export const getDefaultVariantsForGuest = (data) => {
    if (!Array.isArray(data) || data?.length === 0) {
        return []; // Return an empty array if input data is invalid or empty
    }

    return data.map(feature => {
        // Extract the default variant for the feature
        const defaultVariant = feature?.testVariants?.find(variant => variant?.inProduction);

        return {
            featureKey: feature?.featureKey,
            assignedTestVariant: defaultVariant || null, // Include the default variant or null
            experimentKey: feature?.experimentKey,
        };
    }).filter(item => item?.defaultVariant !== null); // Exclude features without a default variant
};



export const getVariantTestKeyWithPlaceholder = (data, featureKey, placeholder = null) => {
    // Find the feature by its key
    const feature = data?.find(item => item?.featureKey?.toLowerCase() === featureKey?.toLowerCase());

    if (!feature || !feature?.assignedTestVariant) {
        return null; // Return null if the feature or its assignedTestVariant is not found
    }

    // Check if placeholder matches, if provided
    if (placeholder && feature?.placeholder !== placeholder) {
        return null; // Return null if the placeholder does not match
    }

    // Return the testKey of the assignedTestVariant
    return feature?.assignedTestVariant.testKey || null;
};

/**
 * @function trackExperimentEvent
 * @description Tracks an experiment event whenever an experiment is triggered.
 * @param {Array} data - The list of experiment features.
 * @param {string} featureKey - The key of the feature to track.
 */
const trackExperimentEvent = (data, featureKey) => {
  const variantVal = getVariantDescription(data, featureKey);
  const experimentKey = getExperimentKey(data, featureKey);
  const segmentVal = getSegmentKey(data, featureKey);

  if (experimentKey && variantVal && segmentVal) {
    handleExperimentEvent({
      experimentName: experimentKey,
      variant: variantVal,
      segment: segmentVal,
    });
  }
};

/**
 * @function - handleExperimentEvent to track experiment event whenever experiment is triggered
 * @param {*} params 
 */
export const handleExperimentEvent = (params = {}) => {
  let eventParam = {
    [`${MIXPANEL.PARAMETER.EXPERIMENT_NAME}`]: params?.experimentName,
    [`${MIXPANEL.PARAMETER.VARIANT_NAME}`]: params?.variant,
    [`${MIXPANEL.PARAMETER.SEGMENT_NAME}`]: params?.segment,
  };
  mixPanelConfig.trackEvent(MIXPANEL.EVENT.EXPERIMENT_STARTED, eventParam);
};

/**
 * @function getSelectedVariant - to get assigned varient of selected feature of experiment
 * @param {*} featureKey - string - name of feature
 * @returns - assigned varient w.r.t to experiment and default handling
 */
export const getSelectedVariant = (featureKey) => {
    const state = store.getState();
    const { assignedExperimentData, appFeatureListing } = state?.headerDetails || {};
    const getTestKey = (data) => getVariantTestKey(data || [], featureKey);

    if ( isUserloggedIn() && assignedExperimentData?.length > 0 && assignedExperimentData.some((data) => data.featureKey.toLowerCase() === featureKey.toLowerCase())
    ) {
      const varientVal = getTestKey(assignedExperimentData).toLowerCase();
      trackExperimentEvent(assignedExperimentData,featureKey)
      return varientVal;

    } else {
     // when user is guest user or no experiment is running on logged in user
      if (appFeatureListing?.length > 0) {
        let resData = getDefaultVariantsForGuest(appFeatureListing);
        const varientVal = getTestKey(resData);
        return varientVal;
      }
    }
    return "";
  };

  /**
   * @function - getSelectedVarientURL - to get url of selected varient specif to feature
   * @param {*} featureName - name of feature for which varient URL is needed
   * @param {*} activeVarient - varient received from BE to be used
   * @returns - URl string of asked variant
   */
  export const getSelectedVariantURL = (featureName, activeVariant) => {
    let selectedFeatureDetails = Object.entries(VARIANT_DETAILS).find(([key]) => key.toLowerCase() === featureName.toLowerCase()),
        selectedVariantUrl = selectedFeatureDetails && selectedFeatureDetails?.[1] && selectedFeatureDetails[1].find((i) => i?.variant?.toLowerCase() === activeVariant?.toLowerCase());
    return selectedVariantUrl?.url ? selectedVariantUrl?.url : "";
};


/**
 * @function getVariantBasedRailRecommendationUrl - Determines which TA route to return
 * @param {string} placeHolder - Placeholder to check in geminiEnabledUsecases
 * @returns {string} - TA Route based on conditions
 */
export const getVariantBasedRailRecommendationUrl = (placeHolder = "" , featureKey, trackEvent = true) => {
    const state = store.getState();
    const { assignedExperimentData ,configResponse , appFeatureListing} = state?.headerDetails || {};
    const geminiEnabledUsecases = configResponse?.data?.config?.geminiEnabledUsecases || [];     // Get geminiEnabledUsecases from reducer

    // Check if user is logged in and experiment is running
    const isExperimentActive = isUserloggedIn() && assignedExperimentData?.length > 0 &&
        assignedExperimentData?.some((data) => data?.featureKey?.toLowerCase() === featureKey?.toLowerCase());

    if(isExperimentActive && trackEvent){
        trackExperimentEvent(assignedExperimentData, featureKey);
    }    

    // If experiment is active and placeholder exists in geminiEnabledUsecases, return Gemini URL
     if (isExperimentActive) {
        const variantVal = getVariantTestKey(assignedExperimentData, featureKey)?.toLowerCase(),
        isGeminiEnabledUsecase = variantVal.toLowerCase() === VARIENT_VALUE.VARIANT_2.toLowerCase() && geminiEnabledUsecases.includes(placeHolder); 

        return isGeminiEnabledUsecase ? ENV_CONFIG.TA_RECOMMENDATION_GEMINI : ENV_CONFIG.TA_RECOMMENDATION;
    } else {
        if (appFeatureListing?.length > 0) {
            const resData = getDefaultVariantsForGuest(appFeatureListing);
            const variantVal = getVariantTestKey(resData, featureKey)?.toLowerCase();
        
            return variantVal === VARIENT_VALUE.VARIANT_2 && geminiEnabledUsecases.includes(placeHolder)
                ? ENV_CONFIG.TA_RECOMMENDATION_GEMINI
                : ENV_CONFIG.TA_RECOMMENDATION;
        }
    }

    // Otherwise, return normal TA recommendation URL
    return ENV_CONFIG.TA_RECOMMENDATION;
};

/**
 * @function - isFeatureExperimentActive
 * @description - Checks if an experiment is active for the given feature.
 * @param {string} featureName - The name of the feature to check for an experiment.
 * @returns {boolean} - Returns true if an experiment is active, otherwise false.
 */
export const isFeatureExperimentActive = (featureName) => {
  const state = store.getState();
  const { assignedExperimentData } = state?.headerDetails || {};

  return (
    isUserloggedIn() &&
    assignedExperimentData?.length > 0 &&
    assignedExperimentData.some(
      (data) => data?.featureKey?.toLowerCase() === featureName?.toLowerCase()
    )
  );
};

/**
 * @function - getFeatureExperimentDetails
 * @description - Checks if any experiment is running for the given feature and returns its details.
 * @param {string} featureName - The name of the feature to check for an experiment.
 * @returns {Object} - An object containing experimentName and variantName if an experiment is running, else an empty object.
 */
export const getFeatureExperimentDetails = (featureName) => {
  const state = store.getState();
  const { assignedExperimentData } = state?.headerDetails || {};


  if (!isFeatureExperimentActive(featureName)) return {}; // return if no experiment

  return {
    [`${MIXPANEL.PARAMETER.EXPERIMENT_NAME}`]:
      getExperimentKey(assignedExperimentData, featureName) || "",
    [`${MIXPANEL.PARAMETER.VARIANT_NAME}`]:
    getVariantDescription(assignedExperimentData, featureName)?.toLowerCase() ||
      "",
    [`${MIXPANEL.PARAMETER.SEGMENT_NAME}`]:
      getSegmentKey(assignedExperimentData, featureName) || "",
  };
};



export const applyFeatureExperiment = (respType) => {
  const state = store.getState();
  const isSearchFeatureActive = isFeatureExperimentActive(FEATURES.SEARCH_FEATURE);
  const isRailRecommendationActive = isFeatureExperimentActive(FEATURES.RAIL_RECOMMENDATION_FEATURE);
  const { assignedExperimentData } = state?.headerDetails || {};
  const checkVariant = getVariantTestKey(assignedExperimentData, FEATURES.RAIL_RECOMMENDATION_FEATURE)?.toLowerCase();
  const expPageSource = get(state.commonContent, 'expPageSource');

  if(isSearchFeatureActive && expPageSource === PAGE_TYPE.SEARCH.toUpperCase()){
    return getFeatureExperimentDetails(FEATURES.SEARCH_FEATURE);
  }
  else if(isRailRecommendationActive && 
    expPageSource === PAGE_TYPE.RECOMMENDATION.toUpperCase() && 
    (checkVariant === VARIENT_VALUE?.VARIANT_1.toLowerCase() ||
    (checkVariant === VARIENT_VALUE?.VARIANT_2.toLowerCase() && respType === GEMINI_SOURCE.RECOMMENDATION))){
    return getFeatureExperimentDetails(FEATURES.RAIL_RECOMMENDATION_FEATURE);
  }

  return {};
};




