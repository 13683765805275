import { ACTION } from "./constants";
import get from "lodash/get";

let initialState = {};

export default function appDownloadReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION.FETCH_APP_DOWNLOAD_DATA:
            return {
                ...state,
                data: get(action, "apiResponse", {}),
            };

        default:
            return state;
    }
}