import get from 'lodash/get';
import { ACTION } from './constants';

const initialState = {
    localFooterList: {},
    globalFooterList: {},
    pageLevelFaqList: {},
    triggerEvent: {},
};

export default function mainReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION.GET_LOCAL_FOOTER:
            return { ...state, localFooterList: get(action, 'apiResponse', {}) };
        case ACTION.GET_GLOBAL_FOOTER:
            return { ...state, globalFooterList: get(action, 'apiResponse', {}) };
        case ACTION.GET_PAGE_LEVEL_FAQ:
            return { ...state, pageLevelFaqList: get(action, 'apiResponse', {}) };
        case ACTION.RESET_LOCAL_FOOTER:
            return { ...state, localFooterList: {} }
        case ACTION.RESET_PAGE_LEVEL_FAQ:
            return { ...state, pageLevelFaqList: {} }
        case ACTION.GET_PUSH_EVENT_DATA:
            return { ...state, triggerEvent : get(action, 'apiResponse', {}) }
        default:
            return state;
    }
}