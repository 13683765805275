export const ACTION = {
  GET_LOCAL_FOOTER: "GET_LOCAL_FOOTER",
  GET_GLOBAL_FOOTER: "GET_GLOBAL_FOOTER",
  GET_PAGE_LEVEL_FAQ: "GET_PAGE_LEVEL_FAQ",
  RESET_LOCAL_FOOTER: "RESET_LOCAL_FOOTER",
  RESET_PAGE_LEVEL_FAQ: "RESET_PAGE_LEVEL_FAQ",
  GET_PUSH_EVENT_DATA: "GET_PUSH_EVENT_DATA"
}

export const FOOTER_TYPE = {
  LOCAL: "local",
  GLOBAL: "global"
}