export const ACTION = {
    FETCH_PI_DATA: 'FETCH_PI_DATA',
    FETCH_PI_ERROR_DATA: 'FETCH_PI_ERROR_DATA',
    GET_SHEMAROO: 'GET_SHEMAROO',
    GET_SHEMAROO_ERROR: 'GET_SHEMAROO_ERROR',
    FETCH_PI_RECOMMENDED_DATA: 'FETCH_PI_RECOMMENDED_DATA',
    FETCH_PI_RECOMMENDED_DATA_ERROR: 'FETCH_PI_RECOMMENDED_DATA_ERROR',
    CLEAR_CONTENT: 'CLEAR_CONTENT',
    TA_RECOMMENDATION_RAIL: 'TA_RECOMMENDATION_RAIL',
    FETCH_CONTINUE_WATCHING_DETAILS: 'FETCH_CONTINUE_WATCHING_DETAILS',
    FETCH_TVOD_EXPIRY_DETAILS: 'FETCH_TVOD_EXPIRY_DETAILS',
    FETCH_PLAYBACK_ELIGIBILITY: 'FETCH_PLAYBACK_ELIGIBILITY',
    TRACK_SHEMAROO_ANALYTICS: 'TRACK_SHEMAROO_ANALYTICS',
    TOGGLE_PI_DETAIL_MOBILE_POPUP: 'TOGGLE_PI_DETAIL_MOBILE_POPUP',
    TRACK_PLANET_MARATHI_ANALYTICS: 'TRACK_PLANET_MARATHI_ANALYTICS',
    FETCH_LIVE_CONTENT_DATA: 'FETCH_LIVE_CONTENT_DATA',
    FETCH_LIVE_ERROR_DATA: "FETCH_LIVE_ERROR_DATA",
    REFETCH_LIVE_CONTENT_DATA: 'REFETCH_LIVE_CONTENT_DATA',
    REFETCH_LIVE_ERROR_DATA: "REFETCH_LIVE_ERROR_DATA",
    LIVE_RAILID: "LIVE_RAILID",
    LIVE_USER_LOGIN: "LIVE_USER_LOGIN",
    FETCH_LIVE_RELATED_RAIL: 'FETCH_LIVE_RELATED_RAIL',
    ACCOUNT_RECOVERY_REQUEST: 'ACCOUNT_RECOVERY_REQUEST',
    FETCH_RECOVERY_URL: 'FETCH_RECOVERY_URL',
    FETCH_PI_PAGE_CTA: 'FETCH_PI_PAGE_CTA',
    PI_REDIRECT_AFTER_SUBSCRIPTION: 'PI_REDIRECT_AFTER_SUBSCRIPTION',
};

export const SUBSCRIPTION = {
    UPGRADE_SUBSCRIPTION: 'Upgrade Plan',
    SELECT_SUBSCRIPTION: 'Subscribe',
    PROCEED: 'Subscribe Now',
    UPGRADE: 'Upgrade',
    UPGRADE_SUBSCRIPTION_DETAIL_1: 'To watch this ',
    UPGRADE_SUBSCRIPTION_DETAIL_2: ', upgrade your plan',
    SELECT_SUBSCRIPTION_DETAIL: 'You are not subscribed to Tata Play Binge',
    EXPIRED_SUBSCRIPTION: 'Expired Subscription',
    EXPIRED_SUBSCRIPTION_DETAIL: 'Your plan has been expired',
    RENEW: 'Renew',
    OK: 'Ok',
    DONE: 'Done',
}

