import MIXPANEL from "@constants/mixpanel";
import mixpanel from "mixpanel-browser";
import { DTH_TYPE, LOCALSTORAGE, PACK_TYPE, SEARCH_PARAM, SECTION_SOURCE, SESSION_STORAGE, UTM_SUPER_PROPERTIES, WEB_SMALL_PAYMENT_SOURCE } from "@constants";
import { deleteKey, getKey, setKey } from "@utils/storage";
import { backendEventWrapper, getDeviceId, getMixpanelId, getMixpanelPeopleProperties, getSearchParam, mixpanelGetSuperProperties, mixpanelGetUTMSuperProperties, setMixpanelId } from "@utils/common";
import { ACCOUNT_STATUS } from "@containers/BingeLogin/APIs/constants";
import isEmpty from "lodash/isEmpty";
import store from "@src/store";
import { getDateFromString } from "./common";
import { isSubscriptionStatusEmpty } from "@containers/Subscription/APIs/subscriptionCommon";


const mixPanelTemp = mixpanel;

let mixPanelConfig = {

    initialData: {},

    identifyUser(identifyUser = false) {
        const isManualLogin = store.getState().loginReducer?.isManualLogin;

        let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
        let mixpanelId = '';
        const mixpanelIdSearchParam = getSearchParam(SEARCH_PARAM.MIXPANEL_ID);
        if (mixpanelIdSearchParam) {
            mixpanelId = mixpanelIdSearchParam;
        }
        else if (!isEmpty(userInfo)) {
            mixpanelId = userInfo?.mixpanelId;
        } else {
            mixpanelId = getMixpanelId();
            if (!mixpanelId) {
                mixpanelId = mixPanelTemp.get_distinct_id();
            }
        }
        setMixpanelId(mixpanelId);
        // call identify only if not accessed from helpCenterWebview and userLoggedIn manually or identifyUser flag is true.
        //  if(!isHelpCenterWebView() &&  (isManualLogin || identifyUser)){
        if (isManualLogin || identifyUser) {
            mixPanelTemp.identify(mixpanelId);
        }
        else {
            mixPanelTemp.register({ "distinct_id": mixpanelId })
        }
        return mixpanelId;
    },


    reset() {
        mixPanelTemp?.reset();
        const distinctId = mixPanelTemp.get_distinct_id();
        setKey(LOCALSTORAGE.MIXPANEL_DISTINCT_ID, distinctId);
        return distinctId;
    },

    resetUserType() {
        this.unsetSuperProperties();
        let logOutSuperProp = {
            [`${MIXPANEL.PARAMETER.USER_TYPE}`]: MIXPANEL.VALUE.GUEST,
            [`${MIXPANEL.PARAMETER.DEVICE_ID}`]: getDeviceId(),
            [`${MIXPANEL.PARAMETER.PLATFORM}`]: MIXPANEL.VALUE.WEB,
        }
        setKey(LOCALSTORAGE.MIXPANEL_SUPER_PROPERTIES, logOutSuperProp);
        mixPanelTemp?.register(logOutSuperProp);
    },

    setPeopleProperties() {
        let properties = getMixpanelPeopleProperties();
        mixPanelTemp?.people?.set(properties);
    },

    setSuperProperties() {
        let superProperties = mixpanelGetSuperProperties();
        let utmSuperProperties = mixpanelGetUTMSuperProperties();
        setKey(LOCALSTORAGE.MIXPANEL_SUPER_PROPERTIES, superProperties);
        if (!isEmpty(utmSuperProperties)) {
            // UTM super properties are not stored in local as it is session specific and thus registered seperately.
            superProperties = {
                ...superProperties,
                ...utmSuperProperties
            }
            sessionStorage.setItem(SESSION_STORAGE.UTM_SUPER_PROPERTIES, JSON.stringify(utmSuperProperties));
        }
        mixPanelTemp?.register(superProperties);
    },

    unsetSuperProperties() {
        let removeSuperPropertyArr = [
            MIXPANEL.PARAMETER.RMN,
            MIXPANEL.PARAMETER.TS_SID,
            MIXPANEL.PARAMETER.C_ID,
            MIXPANEL.PARAMETER.SUBSCRIBED,
            MIXPANEL.PARAMETER.PACK_ID,
            MIXPANEL.PARAMETER.PACK_NAME,
            MIXPANEL.PARAMETER.PACK_PRICE,
            MIXPANEL.PARAMETER.PACK_TENURE,
            MIXPANEL.PARAMETER.PACK_TYPE,
            MIXPANEL.PARAMETER.PACK_END_DATE,
            MIXPANEL.PARAMETER.PACK_START_DATE,
            MIXPANEL.PARAMETER.STACK,
            MIXPANEL.PARAMETER.SID,
        ];
        mixPanelTemp.people.unset(removeSuperPropertyArr);
        removeSuperPropertyArr.map(item => mixPanelTemp.unregister(item));
        deleteKey(LOCALSTORAGE.MIXPANEL_SUPER_PROPERTIES);
    },

    /**
* @function unsetUTMSuperProperties - Unregister utm super properties if already registered on every app launch
*/
    unsetUTMSuperProperties() {
        UTM_SUPER_PROPERTIES.map(property => mixPanelTemp.unregister(`${property}_LastTouch`));
    },

    setGroup() {
        let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {},
            isNonDth = userInfo?.dthStatus === DTH_TYPE.NON_DTH_USER;
        let idValue = isNonDth ? userInfo?.bingeSubscriberId : userInfo?.sId;
        // in case of new user login when rmn comes in bingeSubscriberId no need to set this data, after pubnub push data will be set
        if (!isEmpty(userInfo) && (userInfo?.bingeSubscriberId !== userInfo?.rmn)) {
            mixPanelTemp?.set_group('SID', idValue)
        };
    },

    setGroupProperties() {
        let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
        if (!isEmpty(userInfo)) {
            let properties = getMixpanelPeopleProperties();
            mixPanelTemp?.get_group('SID', userInfo?.sId)?.unset(MIXPANEL.PARAMETER.TS_SID);
            mixPanelTemp?.get_group('SID', userInfo?.sId)?.unset(MIXPANEL.PARAMETER.C_ID);
            mixPanelTemp?.get_group('SID', userInfo?.sId)?.set(properties);
        }
    },

    profileChanges() {
        let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
        if (mixPanelTemp) {
            mixPanelTemp?.people && mixPanelTemp.people.set({
                "FIRST-NAME": `${userInfo?.firstName}`,
                "LAST-NAME": userInfo?.lastName,
                "EMAIL": userInfo?.email,
                "RMN": userInfo?.rmn,
                "SID": userInfo?.sId,
                "DEVICE-ID": getDeviceId(),
            });
        }
    },

    subscriptionDetailChanges() {
        let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {},
            planType = userInfo?.planType,
            subscriptionType = userInfo?.subscriptionType,
            freeTrial = planType?.toLowerCase() === PACK_TYPE.FREE ? MIXPANEL.VALUE.YES : MIXPANEL.VALUE.NO;

        if (mixPanelTemp) {
            mixPanelTemp?.people && mixPanelTemp.people?.set({
                "SUBSCRIBED": userInfo.bingeAccountStatus === ACCOUNT_STATUS.ACTIVE ? MIXPANEL.VALUE.YES : MIXPANEL.VALUE.NO,
                "FREE-TRIAL": !isEmpty(planType) ? freeTrial : '',
                "DATE-OF-SUBSCRIPTION": userInfo?.packCreationDate,
                "PACK-NAME": !isEmpty(planType) ? userInfo.packName : "",
                "PACK-PRICE": !isEmpty(planType) ? userInfo.packPrice : "",
                "SUBSCRIPTION-TYPE": !isEmpty(planType) ? subscriptionType : MIXPANEL.VALUE.UNSUBSCRIBED,
                "PACK-RENEWAL-DATE": getDateFromString(userInfo?.rechargeDue),
                "PACK-START-DATE": new Date(userInfo?.packCreationDate),
                "PACK-END-DATE": getDateFromString(userInfo?.expirationDate),
            });

            /*
            * current subscription api -  null - property update not required
            * current subscription api - user has some pack or written off - FREE-TRIAL-ELIGIBLE - NO
            * */
            if (!isSubscriptionStatusEmpty()) {
                mixPanelTemp?.people && mixPanelTemp.people?.set({
                    "FREE-TRIAL-ELIGIBLE": MIXPANEL.VALUE.NO,
                });
            }
        }
    },

    trackEvent(event, data) {

        const state = store.getState();
        const { configResponse } = state?.headerDetails || {};
         // Get bingeMixPanelEvents  from reducer
        const bingeMixPanelEventsValue = configResponse?.data?.config?.bingeMixpanelEvents || [];
         if( bingeMixPanelEventsValue.includes(event)) {
            backendEventWrapper(event, data);
         }

        let updatedData = {}, keys, sourceIsMSales = getKey(LOCALSTORAGE.PAYMENT_SOURCE_KEY) === WEB_SMALL_PAYMENT_SOURCE.NON_BINGE;
        updatedData = data;
        updatedData && (keys = Object.keys(updatedData));
        if (updatedData && keys.length > 0) {
            keys.forEach(keyName => {
                if (updatedData[keyName] === null || updatedData[keyName] === "null" || updatedData === undefined) {
                    updatedData[keyName] = "";
                } else {
                    updatedData[keyName] = updatedData[keyName] || (updatedData[keyName] === 0 ? 0 : "")
                }
            });

            keys.indexOf(MIXPANEL.PARAMETER.SOURCE) >= 0 &&
                (updatedData[MIXPANEL.PARAMETER.SOURCE] = sourceIsMSales ? MIXPANEL.VALUE.M_SALES : updatedData[MIXPANEL.PARAMETER.SOURCE] || "OTHERS");
            keys.indexOf(MIXPANEL.PARAMETER.TITLE_SECTION) >= 0 &&
                (updatedData[MIXPANEL.PARAMETER.TITLE_SECTION] = updatedData[MIXPANEL.PARAMETER.TITLE_SECTION] || "OTHERS");
            keys.indexOf(MIXPANEL.PARAMETER.PACK_NAME) >= 0 &&
                (updatedData[MIXPANEL.PARAMETER.PACK_NAME] = updatedData[MIXPANEL.PARAMETER.PACK_NAME] || MIXPANEL.VALUE.FREEMIUM);
            keys.indexOf(MIXPANEL.PARAMETER.PACK_TYPE) >= 0 &&
                (updatedData[MIXPANEL.PARAMETER.PACK_TYPE] = updatedData[MIXPANEL.PARAMETER.PACK_TYPE] || MIXPANEL.VALUE.FREEMIUM);
            keys.indexOf(MIXPANEL.PARAMETER.PACK_PRICE) >= 0 &&
                (updatedData[MIXPANEL.PARAMETER.PACK_PRICE] = updatedData[MIXPANEL.PARAMETER.PACK_PRICE] || MIXPANEL.VALUE.DEFAULT_AMOUNT_VALUE);
            keys.indexOf(MIXPANEL.PARAMETER.PAGE_NAME) >= 0 &&
                (updatedData[MIXPANEL.PARAMETER.PAGE_NAME] = updatedData[MIXPANEL.PARAMETER.PAGE_NAME] || MIXPANEL.VALUE.OTHERS);
            keys.indexOf(MIXPANEL.PARAMETER.CONTENT_TYPE) >= 0 &&
                (updatedData[MIXPANEL.PARAMETER.CONTENT_TYPE] = updatedData[MIXPANEL.PARAMETER.CONTENT_TYPE]?.toUpperCase() === SECTION_SOURCE.RECOMMENDATION ? MIXPANEL.VALUE.RECOMMENDATION?.toUpperCase() : MIXPANEL.VALUE.EDITORIAL);
            keys.indexOf(MIXPANEL.PARAMETER.RAIL_TYPE) >= 0 &&
                (updatedData[MIXPANEL.PARAMETER.RAIL_TYPE] = updatedData[MIXPANEL.PARAMETER.RAIL_TYPE]?.toUpperCase() || MIXPANEL.VALUE.EDITORIAL);
        }
        updatedData ? mixPanelTemp.track(event, updatedData) : mixPanelTemp.track(event)

    },
};

export default mixPanelConfig;
