export const ACTION = {
  PACK_LISTING: "PACK_LISTING",
  GET_ACCOUNT_BALALNCE: "GET_ACCOUNT_BALALNCE",
  GET_CURRENT_SUBSCRIPTION_INFO: "GET_CURRENT_SUBSCRIPTION_INFO",
  CHECK_CALLING_SUBSCRIPTION_API: "CHECK_CALLING_SUBSCRIPTION_API",
  VALIDATE_SELECTED_PACK: "VALIDATE_SELECTED_PACK",
  ADD_NEW_PACK: "ADD_NEW_PACK",
  MODIFY_EXISTING_PACK: "MODIFY_EXISTING_PACK",
  CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",
  REVOKE_SUBSCRIPTION: "REVOKE_SUBSCRIPTION",
  SET_UPDATED_TENURE: "SET_UPDATED_TENURE",
  NOTLOGGEDIN_SUBSCRIPTION: "NOTLOGGEDIN_SUBSCRIPTION",
  MINI_SUBSCRIPTION: "MINI_SUBSCRIPTION",
  CLEAR_PACK_LIST: "CLEAR_PACK_LIST",
  PORTAL_PACK_LINK: "PORTAL_PACK_LINK",
  MIGRATE_USER_INFO: "MIGRATE_USER_INFO",
  CAMPAIGN_PAGE_DATA: "CAMPAIGN_PAGE_DATA",
  CAMPAIGN_BANNER_DATA: "CAMPAIGN_BANNER_DATA",
  USER_ELIGIBILE_DATA: "USER_ELIGIBILE_DATA",
  REGIONAL_NUDGE_STATUS: "REGIONAL_NUDGE_STATUS",
  BINGE_TRIAL_STATUS: "BINGE_TRIAL_STATUS",
  CURRENT_SUBCRIPTION_MY_PLAN_VERBIAGES: 'CURRENT_SUBCRIPTION_MY_PLAN_VERBIAGES',
  GET_PRIME_ENTITLEMENT_DETAILS: 'GET_PRIME_ENTITLEMENT_DETAILS',
  FETCH_PRIME_EXISTING_SUBSCRIPTION_FLAG: 'FETCH_PRIME_EXISTING_SUBSCRIPTION_FLAG',
  POST_DETAILS_FOR_PUNUB_SYNC: 'POST_DETAILS_FOR_PUNUB_SYNC',
  RENEW_NUDGE_STATUS: "RENEW_NUDGE_STATUS",
  HELP_CENTER_DELETE_ACCOUNT_VERBIAGE: 'HELP_CENTER_DELETE_ACCOUNT_VERBIAGE',
  VALIDATE_SUBSCRIPTION_COUPON: 'VALIDATE_SUBSCRIPTION_COUPON',
  GET_APPLE_PRIME_STATUS: "GET_APPLE_PRIME_STATUS",
  APPLE_ACTIVATE_RENEW_NUDGE: 'APPLE_ACTIVATE_RENEW_NUDGE',
  GUEST_USER_VALIDATE_COUPON: 'GUEST_USER_VALIDATE_COUPON',
};

export const PREMIUM = "premium vip" || "premium vip";

export const TENURE_MSG = "change tenure message";

export const SUBSCRIPTION_STATUS = {
  ACTIVE: "ACTIVE",
  DEACTIVE: "DEACTIVE",
  EXPIRED: "EXPIRED",
  WRITTEN_OFF: "WRITTEN_OFF",
};

export const ACCOUNT_STATUS = {
  ACTIVE: "ACTIVE",
  DEACTIVATED: "DEACTIVATED",
  DEACTIVE: "DEACTIVE",
  CANCELLED: "CANCELLED",
  WRITTEN_OFF: "WRITTEN_OFF",
  TEMP_SUSPENSION: "TEMP_SUSPENSION",
  SUB_STATUS_PARTIALLY_DUNNED: "PARTIALLY DUNNED",
  INACTIVE: "INACTIVE",
  PENDING: "PENDING",
};

export const PRIME_STATUS = {
  ACTIVATED: "ACTIVATED",
  CANCELLED: "CANCELLED",
  CANCELLED_INITIATED: "CANCELLED_INITIATED",
  SUSPENDED: "SUSPENDED"
}

export const PACK_VALIDATE_DELAY_WAIT_TIME = 10000;

export const DISCOUNT_SOURCE = 'discount';

export const TICK_TICK = {
  SUCCESS: "SUCCESS",
  LOGIN: "LOGIN",
  FAIL: "FAIL",
  EXISTING_LOGIN: "EXISTINGLOGIN",
  SUCCESS_SKIP_PG: "SUCCESSSKIPPG",
}

export const JOURNEY_SOURCE = {
  MYPLAN_CHANGE: "MYPLAN#CHANGE",
  HOME_CONTENT: "HOME#CONTENT",
  MYPLAN_TENURE: "MYPLAN#TENURE",
  DRAWER_MYOP: "DRAWER#MYOP",
  DRAWER_CYOP: "DRAWER#CYOP",
  CURATED_PACK_SELECTION: "curated-pack-selection",
  CONTENT_PLAY: "content-play",
  MY_PLAN_EDIT: "my-plan-edit",
  MY_PLAN_REGIONAL: "MYPLAN#REGIONAL",
  TIK_TIK_MYPLAN: "TICKTICK#APPREPLACE",
  TICK_TICK_APP_DISCONTINUE: "TICKTICK#APPDISCONTINUE",
  PRIME_BANNER : 'PRIME_BANNER',
  COUPON_REDEEM: 'COUPON-REDEEM',
  PRIME_BANNER: 'PRIME_BANNER',
  DRAWER_ZEROAPPSELECTION: 'DRAWER#ZEROAPPSELECTION',
  PRIME_BANNER: 'PRIME_BANNER',
  PLAN_APPSELECTION: 'PLAN#APPSELECTION',
  PLAN_CHANGETENURE: 'PLAN#CHANGETENURE',
}

export const PACK_NAME = 'pack-name'

export const POLLING_MAX_COUNT = 30

export const DISCOUNT_PERCENTAGE = "PERCENTAGE";
export const DISCOUNT_FIXED = "FIXED";

export const partnerImages = [
  { url: '../../../assets/images/disney.png' },
  { url: '../../../assets/images/sony.png' },
  { url: '../../../assets/images/zee.png' },
  { url: '../../../assets/images/voot.png' },
  { url: '../../../assets/images/lionsgate.png' },
  { url: '../../../assets/images/aha.png' },
  { url: '../../../assets/images/mx.png' },
  { url: '../../../assets/images/eros.png' },
  { url: '../../../assets/images/hoichoi.png' },
];

export const FORCE_LOGOUT_VERBIAGE = {
  MESSAGE: "You may have one or more cancelled plan(s). Please login again to subscribe to a new plan.",
  TITLE: "Cancelled Plan(s)",
};

export const RETRY_CASE_SUCCESS_SCREEN_ERROR = 19025;

export const PUBNUB_BE_LS_SYNC_SOURCE = {
  ZERO_APPS: 'ZEROAPPS'
}

export const OG_STATIC_DATA_SUBSCRIPTION = {
    META_TITLE: 'Tata Play Binge OTT Subscription Plans and Pricing | Access 30+ OTT Apps',
    META_DESCRIPTION: 'Looking for an affordable  OTT subscription? Tata Play Binge offers a single subscription for 30+ platforms. Check out the subscription price and plan details now!',
}