import { doRequest } from "@src/services/api";
import { serviceConstants } from "@utils/apiService";

class MainService {

    getAppFooterData(type,ids) {
        return doRequest(serviceConstants.getAppFooterData(type,ids));
    }

    getPageLevelFAQs(data) {
        return doRequest(serviceConstants.getPageLevelFAQs(data));
    }

    triggerEventPushData(data) {
        return doRequest(serviceConstants.triggerEventPushData(data));
    }

}

const MainServiceInstance = new MainService();
export default MainServiceInstance;
