import mixPanelConfig from '@utils/mixpanel';
import moengageConfig from '@utils/moengage';
import { getCommonAnalyticsAttributes } from "@containers/PIDetail/PIDetailCommon";
import { useLocation } from 'react-router-dom';
import { getBaseUrl, primeMixpanelParameter } from '@utils/common';
import { getKey } from '@utils/storage';
import { LOCALSTORAGE, MESSAGE } from '@utils/constants';
import { isEmpty } from 'lodash';
import MIXPANEL from "@constants/mixpanel";


export default function useContentAnalytics() {
    const location = useLocation();
    let state = {
        mixpanelData: {
            railTitle: location?.state?.railTitle,
            source: location?.state?.source,
            origin: location?.state?.prevPath,
            railPosition: location?.state?.railPosition,
            conPosition: location?.state?.conPosition,
            sectionSource: location?.state?.sectionSource,
            configType: location?.state?.configType,
            sectionType: location?.state?.sectionType,
            contentSectionSource: location?.state?.contentSectionSource,
        },
    }

    const trackMixPanelEvent = (props, eventName, isApple, isDeeplinkRedirect = false) => {
        const data = getCommonAnalyticsAttributes(eventName, props, state, isApple, isDeeplinkRedirect);
        mixPanelConfig.trackEvent(eventName, data.mixpanelData);
    };

    const trackMongageEvent = (props, eventName) => {
        const data = getCommonAnalyticsAttributes(eventName, props, state);
        moengageConfig.trackEvent(eventName, data.moengageData);
    };

    const zee5TagMixpanelEvents = (props, tag) => {
        let eventName = tag ? MIXPANEL.EVENT.ZEE5_TAG_API_SUCCESS : MIXPANEL.EVENT.ZEE5_TAG_API_FAILURE,
            playContentData = getCommonAnalyticsAttributes(eventName, props, state, true),
            { baId } = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {},
            error, code, tagResponse = props?.response;

        if (isEmpty(tagResponse?.response)) {
            error = MESSAGE.REQUEST_TIME_OUT;
            code = tagResponse?.code;
        } else if (tagResponse?.response) {
            code = tagResponse?.response?.status;
            error = tagResponse?.response?.data?.message;
        } else {
            error = MESSAGE.OPERATION_NOT_COMPLETED;
            code = -1
        }
        let commonData = {
            [`${MIXPANEL.PARAMETER.API_URL}`]: `${getBaseUrl()}${'zee5-playback-api/v2/tag/fetch'}`,
            [`${MIXPANEL.PARAMETER.DEEPLINK_URL}`]: props?.meta?.partnerDeepLinkUrl,
        }
        let noTagData = {
            [`${MIXPANEL.PARAMETER.REASON}`]: error,
            [`${MIXPANEL.PARAMETER.ERROR_CODE}`]: code,
            [`${MIXPANEL.PARAMETER.REQUEST_PARAMS}`]: { "partnerUniqueId": props?.partnerUniqueId, "baId": baId },
        }

        let mixData = tag ? { ...playContentData?.mixpanelData, ...commonData, [`${MIXPANEL.PARAMETER.TAG_VALUE}`]: tag || '' } : { ...playContentData?.mixpanelData, ...noTagData, ...commonData }
        mixPanelConfig.trackEvent(eventName, mixData)
    }

    const trackPrimeMixpanel = (eventName) => {
        mixPanelConfig.trackEvent(eventName, {
            ...(primeMixpanelParameter()),
        })
    }

    return {
        trackMixPanelEvent,
        trackMongageEvent,
        zee5TagMixpanelEvents,
        trackPrimeMixpanel,
    };
}
